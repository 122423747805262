import styles from './ProductList.module.css'
import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Title from './Title.js'
import QuantityBtn from './QuantityBtn'

const ProductList = () => {

    /*let productList = [
        {
            "id" : 1,
            "name" : "蘋果",
            "price" : 5,
            "image" : "apple.jpg",
            "description" : "新鮮蘋果50克，一日一蘋果，醫生遠離我"
        },
        {
            "id" : 2,
            "name" : "橙",
            "price" : 3,
            "image" : "orange.jpg",
            "description" : "新鮮橙50克，又甜又好吃"
        },
        {
            "id" : 3,
            "name" : "芒果",
            "price" : 4,
            "image" : "mange.jpg",
            "description" : "新鮮芒果500克，宜做甜品"
        },
        {
            "id" : 4,
            "name" : "西瓜",
            "price" : 20,
            "image" : "watermelon.jpg",
            "description" : "新鮮西瓜2公斤，夏季必備"
        },
        {
            "id" : 5,
            "name" : "藍梅",
            "price" : 10,
            "image" : "blueberry.jpg",
            "description" : "新鮮藍梅50克，補眼之寶"
        },
        {
            "id" : 6,
            "name" : "白蘿蔔",
            "price" : 5,
            "image" : "white-carrot.jpg",
            "description" : "新鮮白蘿蔔1公斤，宜煲湯"
        }
    ]*/

    let [productList, setProductList] = useState([])

    useEffect(()=>{
        //1. 無第二個參數：component每次render都會觸發
        //2. Dependency Array是空array時：只會在第一次網頁render時觸發
        //3. Dependency Array是有變數時：第一次網頁render時 ＋ 指定的變數改變時觸發
        fetch("https://hoyinleung.github.io/demoapi/react-basic-product.json")
        .then(response => response.json())
        .then(data => setProductList(data))
    },[])


    //const [showProduct, setShowProduct] = useState(false)

    return (
        <>
            {/*showProduct && <button onClick={()=>{setShowProduct(false)}}>隱藏產品</button>*/}
            {/*!showProduct && <button onClick={()=>{setShowProduct(true)}}>顯示產品</button>*/}
            
            <Title mainTitle='請選擇要購買的水果' subTitle='今日有九折'/>

            <div>
                {
                    //showProduct && 
                    productList.map((product) => {
                        return (
                            <div className={styles.productBorder} key={product.id}>
                                {product.name}<br/>
                                {product.price}<br/>
                                <Link to={"/product/"+product.id}>
                                    <img src={process.env.PUBLIC_URL+'/img/'+product.image} width='200px' alt='Product'/><br/>
                                </Link>
                                {product.description}<br/>
                                <QuantityBtn productInfo={product}/>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default ProductList