import React, { useContext } from 'react'
import Title from './Title.js'
import { Link } from 'react-router-dom'
import { CartContext } from './CartContext.js'
import QuantityBtn from './QuantityBtn'

const CheckOut = () => {

    let {cartItems} = useContext(CartContext)
    let cartEmpty = cartItems.length <= 0 ? true : false
    let grandTotal = cartItems.reduce((total,product)=>{
        return total += product.price * product.quantity
    },0)
    const freeShippingPrice = 99

  return (
    <>
        <Title mainTitle='你的購物車'/>

        {
            cartEmpty &&
            <div>
                購物車現在沒有商品<br/>
                <Link to='/'>去產品頁看看吧</Link>
            </div>
        }


        {
            !cartEmpty &&
            <div>
                <div id='cartSection'>
                    {
                        /* 產品列表 */
                        cartItems.map((product)=>(
                            <div key={product.id}>
                                <img src={process.env.PUBLIC_URL+'/img/'+product.image} width='200px' alt='product'/><br/>
                                {product.name}<br/>
                                {product.description}<br/>
                                {product.price}<br/>
                                購買數量：{product.quantity}
                                <QuantityBtn productInfo={product}/>
                            </div>
                        ))
                    }
                </div>
                <div id='checkOutSection'>
                    {
                        /* 價錢總數 */
                        <div>
                            全部貨品總共<br/>
                            {grandTotal}元
                        </div>
                    }
                    {
                        /* 免費送貨 */
                        grandTotal >= freeShippingPrice ?
                        <div>我們免費送貨</div> :
                        <div>
                            滿${freeShippingPrice}免費送貨<br/>
                            還差${freeShippingPrice-grandTotal}
                        </div>
                    
                    }
                </div>
            </div>
        }

    </>
  )
}

export default CheckOut