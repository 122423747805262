import ProductList from "./ProductList";
import {BrowserRouter, Routes, Route, Link} from 'react-router-dom';
import CheckOut from "./CheckOut";
import ProductDetail from "./ProductDetail";
import { CartContext } from "./CartContext";
import { useState } from 'react'

function App() {

  const [cartItems, setCartItems] = useState([])

  return (
    <BrowserRouter>
      <CartContext.Provider value={{cartItems,setCartItems}}>
        <Link to = "/">首頁</Link>
        <Link to = "/checkout">購物</Link>
        <Routes>
          <Route path="/" element={<ProductList/>}/>
          <Route path="/product" element={<ProductDetail/>}>
            <Route path=":id" element={<ProductDetail/>}/>
          </Route>
          <Route path="/checkout" element={<CheckOut/>}/>
          <Route path="*" element={<p>找不到頁面</p>}/>
        </Routes>
      </CartContext.Provider>
    </BrowserRouter>
  );
}

export default App;
