import React from 'react'

const title = (props) => {
  return (
    <>
      <h1 style={{borderBottom: '5px solid red', textAlign:"center"}}>{props.mainTitle}</h1>
      <h2>{props.subTitle}</h2>
    </>
  )
}

export default title