import React, { useContext, useState } from 'react'
import { CartContext } from './CartContext'

const QuantityBtn = ({productInfo}) => {

    //hook to cartContext
    const {cartItems, setCartItems} = useContext(CartContext)

    
    //購物車內有冇該產品
    let productIndexInCart = cartItems.findIndex((element)=>{
        return element.id === productInfo.id
    })
    //findIndex
    //如果係購物車內找到該件產品 => 返回索引位置 0, 1, 2, 3...
    //該件產品沒有被加入過去購物車 => 返回 -1

    let [numInCart, setNumInCart] = useState(
        (productIndexInCart===-1) ? 0 : cartItems[productIndexInCart].quantity
    )

    let handleAdd = ()=> {

        if (productIndexInCart===-1) {
            //購物車本身沒有，在 cartItems array 中加個新 element (object)
            setCartItems(
                [
                    {
                        id: productInfo.id,
                        name: productInfo.name,
                        image: productInfo.image,
                        price: productInfo.price,
                        description: productInfo.description,
                        quantity: 1
                    },
                    ...cartItems
                ])
        } 
        else {

            //購物車有該產品，只加個quantity
            let newCartArray = [...cartItems]
            newCartArray[productIndexInCart].quantity++
            setCartItems(newCartArray)

        }

        setNumInCart(numInCart+1)
    }
    let handleSubstract = ()=> {

        if (cartItems[productIndexInCart].quantity===1) {
            //購物車中只剩一件的話，remove object
            let newCartArray = [...cartItems]
            newCartArray.splice(productIndexInCart,1)
            setCartItems(newCartArray)
        } else {
            //減quantity
            let newCartArray = [...cartItems]
            newCartArray[productIndexInCart].quantity--
            setCartItems(newCartArray)
        }

        setNumInCart(numInCart-1)
    }

  return (
    <>
        {
            (numInCart === 0) ?
            <button onClick={handleAdd}>加入購物車</button> :
            <div>
                <button onClick={handleSubstract}>-</button>
                {numInCart}件
                <button onClick={handleAdd}>+</button>
            </div>
        }
    </>
  )
}

export default QuantityBtn